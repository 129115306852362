import * as common from "./modules/common.js"
import * as navigation from "./modules/navigation.js"
import * as dashboard from "./modules/dashboard.js"
import "@fortawesome/fontawesome-free/css/fontawesome.min.css"
import "@fortawesome/fontawesome-free/css/solid.min.css"
import "@fortawesome/fontawesome-free/css/regular.min.css"

globalThis.common = common;
globalThis.navigation = navigation;
globalThis.dashboard = dashboard;
