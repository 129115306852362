﻿import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

interface DateStat {
    label: string
    checkIn: number
    checkOut: number
    drinked: number
}

interface DateUsage {
    date: string
    count: number
}

interface KioskUsageStat {
    label: string
    usage: DateUsage[]
}

interface ChartInfo {
    workTotal: number
    workNow: number
    checkedValid: number
    checkedInvalid: number
    kioskTotal: number
    kioskOp: number
    stat: DateStat[]
    kioskStat: KioskUsageStat[]
}

Chart.register(ChartjsPluginStacked100.default);

export async function renderChart(chartInfo: ChartInfo) {
    new Chart(
        document.getElementById('chartCircle1') as HTMLCanvasElement,
        {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [chartInfo.workNow, chartInfo.workTotal - chartInfo.workNow],
                    backgroundColor: ['#43C343', '#E6E6E6'],
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                events: [],
                cutout: '80%',
            }
        }
    );
    new Chart(
        document.getElementById('chartCircle2') as HTMLCanvasElement,
        {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [chartInfo.checkedInvalid, chartInfo.workTotal - chartInfo.checkedInvalid],
                    backgroundColor: ['EFA93A', '#E6E6E6'],
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                events: [],
                cutout: '80%',
            }
        }
    );
    new Chart(
        document.getElementById('chartCircle3') as HTMLCanvasElement,
        {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [chartInfo.kioskOp, chartInfo.kioskTotal - chartInfo.kioskOp],
                    backgroundColor: ['#3C8CFE', '#E6E6E6'],
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                events: [],
                cutout: '80%',
            }
        },
    );

    new Chart(
        document.getElementById('chartWork') as HTMLCanvasElement,
        {
            type: 'bar',
            data: {
                labels: chartInfo.stat.map(e => e.label),
                datasets: [{
                    label: '출근',
                    data: chartInfo.stat.map(e => e.checkIn),
                    backgroundColor: '#3C8CFE',
                },
                {
                    label: '퇴근',
                    data: chartInfo.stat.map(e => e.checkOut),
                    backgroundColor: '#A980F8',
                },
                {
                    label: '음주',
                    data: chartInfo.stat.map(e => e.drinked),
                    backgroundColor: '#ED6FDE',
                }]
            },
            options: {
                maintainAspectRatio: false,
            }
        }
    );

    new Chart(
        document.getElementById('chartKioskUsage') as HTMLCanvasElement,
        {
            type: 'bar',
            data: {
                labels: [...new Set<string>(chartInfo.kioskStat.flatMap(ks => ks.usage).map(u => u.date))].sort(),
                datasets: chartInfo.kioskStat.map(ks => ({
                    label: ks.label,
                    data: ks.usage.map(du => du.count),
                })),
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        beforeUpdate(axis) {
                            const labels = axis.chart.data.labels;
                            for (let i = 0; i < labels.length; i++) {
                                const dateUsageIndex = labels[i];
                                labels[i] = dateUsageIndex.toString().substring(5);
                            }
                        }
                    }
                },
                plugins: {
                    stacked100: { enable: true }
                }
            }
        }
    )
}
