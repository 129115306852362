import { gsap, Quint } from "gsap";

export function anyActiveChild(target: HTMLElement) {
    return target.querySelector('.active') ? true : false;
}

export function navigationSelect(submenu: HTMLElement, value: boolean) {
    gsap.killTweensOf(submenu);
    const h = value ? submenu.querySelector('ul')!.clientHeight : 0;
    gsap.to(submenu, { height: h, ease: Quint.easeOut, duration: 0.5 });
}

export function showSearch() {
    const search = document.querySelector('.search_section') as HTMLElement;
    if (search.offsetHeight === 0) {
        search.style.height = 'auto';
        search.style.margin = '0 0 16px 0';
    } else {
        search.style.height = '0px';
        search.style.margin = '0';
    }
}

export function navigationShow(show) {
    const cover = document.querySelector('.nav_cover') as HTMLElement;
    const nav_left = document.querySelector('.nav_left') as HTMLElement;
    if (show) {
        cover.style.display = 'flex';
        nav_left.style.width = '240px';
    } else {
        cover.style.display = 'none';
        nav_left.style.width = '0px';
    }
}

export function navigationWatch() {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    mediaQuery.addEventListener('change', e => {
        navigationShow(mediaQuery.matches);
    });
}
