﻿export function startWatch(target) {
    target ??= document.querySelector('.nav_account_date');
    if (target == null) {
        return;
    }
    const now = new Date(Date.now());

    function zeroPad(value) {
        if (value < 10) {
            value = "0" + value;
        }
        return value;
    }
    const yyyy = now.getFullYear();
    const MM = zeroPad(now.getMonth() + 1);
    const dd = zeroPad(now.getDate());
    const HH = zeroPad(now.getHours());
    const mm = zeroPad(now.getMinutes());
    const ss = zeroPad(now.getSeconds());
    target.innerHTML =
        `<h4>${yyyy}년 ${MM}월 ${dd}일</h4>` +
        `<h4>${HH}:${mm}:${ss}</h4>`;
    setTimeout(startWatch, 1000, target);
}
